<template>
  <div class="product-grid bg-white">
    <div class="ss-header mb-3">
      <span class="title-desc"><strong>Поездки</strong></span>
    </div>
    <ul id="myTab" class="nav nav-tabs mb-3" role="tablist">
      <li class="nav-item" role="presentation">
        <a class="nav-link active" id="active-tab" data-toggle="tab" href="#active" role="tab" aria-controls="active"
          aria-selected="false">
          Текущие поездки
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="completed-tab" data-toggle="tab" href="#completed" role="tab" aria-controls="completed"
          aria-selected="true">
          Прошедшие поездки
        </a>
      </li>
    </ul>
    <div class="tab-content mb-5">
      <div role="tabpanel" class="tab-pane fade show active" id="active">
        <div class="flex-table" role="table" v-if="activeItems && activeItems.length > 0">
          <div class="flex-table-row flex-table-header flex-row">
            <div class="flex-table-cell">Статус</div>
            <div class="flex-table-cell">Дата создания</div>
            <div class="flex-table-cell">Номер заказа</div>
          </div>
          <div id="accordion" class="flex-table-body accordion">
            <div class="flex-table-row flex-column accordion-item" v-for="item in activeItems" :key="item.id">
              <div :id="'heading' + item.id" class="accordion-header flex-table-row">
                <div class="flex-table-row collapsed accordion-toggle" role="button" @click="getFile(item.id)"
                  data-toggle="collapse" :data-target="'#collapse' + item.id" aria-expanded="true"
                  :aria-controls="'collapse' + item.id">
                  <div class="flex-table-cell">
                    <b class="d-lg-none">Статус:</b>
                    <span :class='getServiceStatusClass(item.status)' class="visit-request-order-status">{{
                    item.status_name
                    }}</span>
                  </div>
                  <div class="flex-table-cell">
                    <b class="d-lg-none">Дата создания:</b>
                    {{ item.date }}
                  </div>
                  <div class="flex-table-cell product-order_number">
                    <b class="d-lg-none">Номер заказа:</b> {{ item.number }}
                  </div>
                </div>
              </div>
              <div v-if="item.visit" :id="'collapse' + item.id" class="flex-table-row accordion-collapse collapse"
                :aria-labelledby="'heading' + item.id" data-parent="#accordion">
                <div class="flex-table-cell px-0 py-0 w-100">
                  <div class="accordion-body">
                    <table class="table table-visit-orders">
                        <thead>
                          <tr>
                            <th>Наименование</th>
                            <th style="width: 140px">Кол-во человек</th>
                            <th style="width: 115px">Дата поездки</th>
                          </tr>
                        </thead>
                        <tbody>
                          <td>{{item.visit.title}}</td>
                          <td>{{item.user_count}}</td>
                          <td>{{item.visit_date}}</td>
                        </tbody>
                    </table>
                    <div v-if="item.status_name === 'Подтвержден'">
                      <div class="accordion__document-block">
                        <div class="accordion__document-block-title"> Список участников поездки </div>
                        <div class="accordion__document-block-desc"> Для предоставлении информации об&nbsp;участниках
                          поездки необходимо загрузить файл в&nbsp;формате .xlsx </div>
                        <div class="accordion__document-block-desc"> Все поля в&nbsp;файле обязательны для заполнения
                        </div>
                        <div class="accordion__document-block-load" @click="loadFile(item.id)">
                          <LoadIcon /> Скачать форму участников поездки
                        </div>
                        <div class="accordion__document-block-document" v-if="fileName || fileLoad && has_file">
                          <FileIcon />
                          <div v-if="fileName">
                            {{fileName}}
                          </div>
                          <div @click='loadGetFile(item.id)' v-else :style="{
                            color: disabledFile ? '#A0A0A0' : '#D2233C'
                          }" class="accordion__document-block-load">
                            Список участников поездки.xlsx
                          </div>
                          <ClearFile v-if="!disabledFile" style='cursor:pointer' @click.native='clearFile(item.id)' />
                        </div>
                        <div :class="{
                          disabled: disabledFile
                        }" class="accordion__document-block-document-load"
                          @click="disabledFile ? '' : uploadFile(item.id)"> Загрузить файл
                        </div>
                        <div class="visit-detail__block">
                          <div class="visit-detail__description">
                            <div class="accordion__document-block-document-desc">
                              <DangerIcon />
                              <div class="accordion__document-block-document-text">
                                Документ можно загрузить и&nbsp;редактировать в&nbsp;течение 10&nbsp;дней после
                                согласования поездки.
                                По&nbsp;истечении этого срока возможность редактирования будет недоступна. Если документ
                                не&nbsp;был прикреплен, завод имеет право отменить поездку.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="accordion__document-block-title" style="margin-top: 40px;"> План поездки </div>
                        <div class="accordion__document-block-desc">Все поля обязательны для заполнения</div>
                        <div class="visit-order-form">
                          <div class="visit-order-form__title">
                            Информация о поездке
                          </div>
                          <ValidationObserver slim v-slot="{ invalid, handleSubmit }">
                            <form @submit.prevent="handleSubmit(postRequest(item.id))">
                              <div class="visit-order-form__container">
                                <div class="visit-order-form__element-title">
                                  Дата, время прибытия на&nbsp;предприятие
                                </div>
                                <div class="visit-order-form__element-content">
                                  <ValidationProvider :name="item.visit_date" rules="required">
                                    <input v-model="item.visit_date" readonly placeholder="22-04-2022"
                                      class="form-control" type="text" maxlength="35" />
                                  </ValidationProvider>
                                  <ValidationProvider style="margin: 0 10px;" :name="time" rules="required">
                                    <input style="width: 100px;" type="time" v-model="time" v-mask="'##:##'"
                                      placeholder="10:10" :disabled='disabledForm' class="form-control" maxlength="5" />
                                  </ValidationProvider>
                                </div>
                              </div>
                              <div class="visit-order-form__container">
                                <div class="visit-order-form__element-title">
                                  Гос. номер&nbsp;ТС для въезда на&nbsp;предприятие
                                </div>
                                <div class="visit-order-form__element-content">
                                  <ValidationProvider :name="state_number" rules="required">
                                    <input v-model="state_number" :disabled='disabledForm' placeholder="А777АА"
                                      class="form-control" type="text" maxlength="6" />
                                  </ValidationProvider>
                                </div>
                              </div>
                              <div class="visit-order-form__container">
                                <div class="visit-order-form__element-title">
                                  Место проживания участников
                                </div>
                                <div class="visit-order-form__element-content">
                                  <ValidationProvider :name="hotel" rules="required">
                                    <input placeholder="Название отеля" :disabled='disabledForm' v-model="hotel" class="form-control" type="text"
                                      maxlength="35" />
                                  </ValidationProvider>
                                </div>
                              </div>
                              <div class="visit-order-form__container">
                                <div class="visit-order-form__element-title">
                                  Транспорт прибытия участников
                                </div>
                                <div class="visit-order-form__element-content">
                                  <ValidationProvider :name="transport" rules="required">
                                    <input placeholder="Транспорт" :disabled='disabledForm' v-model="transport" class="form-control" type="text"
                                      maxlength="35" />
                                  </ValidationProvider>
                                </div>
                              </div>
                              <div class="visit-order-form__container">
                                <div class="visit-order-form__element-title">
                                  Место проведения делового ужина
                                </div>
                                <div class="visit-order-form__element-content">
                                  <ValidationProvider :name="dinner_place" rules="required">
                                    <input placeholder="Название заведения" :disabled='disabledForm' v-model="dinner_place" class="form-control"
                                      type="text" maxlength="35" />
                                  </ValidationProvider>
                                </div>
                              </div>
                              <div class="visit-order-form__container" style="padding-top: 30px;">
                                <div class="visit-order-form__element-title">
                                  Нужна ли сессия вопросы/ответы после экскурсии?
                                  <Tooltip text_tooltip='Семинар проходит на территории предприятия' />
                                </div>
                                <div class="visit-order-form__element-content">
                                  <div class="visit-order-form__element-content-label"> Нет </div>
                                  <label class="switch">
                                    <input type="checkbox" :disabled='disabledForm' v-model="have_questions" />
                                    <span class="slider round"></span>
                                  </label>
                                  <div class="visit-order-form__element-content-label"> Да </div>
                                </div>
                              </div>
                              <div class="visit-order-form__container">
                                <div class="visit-order-form__element-title">
                                  Нужен ли обед на территории ТПП?
                                  <!-- <Tooltip text_tooltip='Семинар проходит на территории предприятия' /> -->
                                </div>
                                <div class="visit-order-form__element-content">
                                  <div class="visit-order-form__element-content-label"> Нет </div>
                                  <label class="switch">
                                    <input type="checkbox" :disabled='disabledForm' v-model="have_lunch" />
                                    <span class="slider round"></span>
                                  </label>
                                  <div class="visit-order-form__element-content-label"> Да </div>
                                </div>
                              </div>
                              <div class="visit-order-form__container">
                                <div class="visit-order-form__element-title">
                                  Планируется ли экскурсия по городу?
                                  <!-- <Tooltip text_tooltip='Семинар проходит на территории предприятия' /> -->
                                </div>
                                <div class="visit-order-form__element-content">
                                  <div class="visit-order-form__element-content-label"> Нет </div>
                                  <label class="switch">
                                    <input type="checkbox" :disabled='disabledForm' v-model="have_city_excursion" />
                                    <span class="slider round"></span>
                                  </label>
                                  <div class="visit-order-form__element-content-label"> Да </div>
                                </div>
                              </div>
                              <div class="visit-order-form__container">
                                <div class="visit-order-form__element-title">
                                  Планируется ли фотосъемка?
                                  <!-- <Tooltip text_tooltip='Семинар проходит на территории предприятия' /> -->
                                </div>
                                <div class="visit-order-form__element-content">
                                  <div class="visit-order-form__element-content-label"> Нет </div>
                                  <label class="switch">
                                    <input type="checkbox" :disabled='disabledForm' v-model="have_photo" />
                                    <span class="slider round"></span>
                                  </label>
                                  <div class="visit-order-form__element-content-label"> Да </div>
                                </div>
                              </div>
                              <div class="visit-order-form__container">
                                <button type="submit" :disabled="invalid || disabledForm" :class='{
                                  disabled: invalid || disabledForm
                                }' class="accordion__document-block-document-load">Отправить</button>
                              </div>
                              <div class="visit-detail__block">
                                <div class="visit-detail__description">
                                  <div class="accordion__document-block-document-desc">
                                    <DangerIcon />
                                    <div class="accordion__document-block-document-text">
                                      За&nbsp;7&nbsp;дней до&nbsp;поездки возможность редактирования будет недоступна.
                                      В&nbsp;случае непредставления информации в&nbsp;срок за&nbsp;7&nbsp;дней
                                      до&nbsp;поездки, завод имеет право отменить поездку.
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div v-if="invalid"> </div>
                            </form>
                          </ValidationObserver>
                        </div>
                      </div>
                      <div>
                        <div class="visit-detail__block">
                          <div class="visit-detail__block-list-title">
                            Для завершения оформления заявки вам нужно будет заполнить анкету поездки
                          </div>
                        </div>
                        <div class="visit-detail__block">
                          <div class="visit-detail__block-list-title">
                            Пришлем уведомление на почту когда заявка будет согласована
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-md-center" v-if="!activeItems || !(activeItems.length > 0)">
          <div class="ss-header">
            <span class="title-desc"><strong>Тут пока ничего нет.</strong></span>
          </div>
        </div>
      </div>
      <div role="tabpanel" class="tab-pane fade" id="completed">
        <div class="flex-table" role="table" v-if="completeItems && completeItems.length > 0">
          <div class="flex-table-row flex-table-header flex-row">
            <div class="flex-table-cell">Статус</div>
            <div class="flex-table-cell">Дата создания</div>
            <div class="flex-table-cell">Номер заказа</div>
          </div>
          <div id="accordion2" class="flex-table-body accordion">
            <div class="flex-table-row flex-column accordion-item" v-for="item in completeItems" :key="item.id">
              <div :id="'heading' + item.id" class="accordion-header flex-table-row">
                <div class="flex-table-row collapsed accordion-toggle" role="button" data-toggle="collapse"
                  :data-target="'#collapse' + item.id" aria-expanded="true" :aria-controls="'collapse' + item.id">
                  <div class="flex-table-cell">
                    <b class="d-lg-none">Статус:</b>
                    <span class="visit-request-order-status" :class="getServiceStatusClass(item.status)">
                      {{ item.status_name }}
                    </span>
                  </div>
                  <div class="flex-table-cell">
                    <b class="d-lg-none">Дата создания:</b>
                    {{ item.date }}
                  </div>
                  <div class="flex-table-cell product-order_number">
                    <b class="d-lg-none">Номер заказа:</b> {{ item.number }}
                  </div>
                </div>
              </div>
              <div v-if="item.visit" :id="'collapse' + item.id" class="flex-table-row accordion-collapse collapse"
                :aria-labelledby="'heading' + item.id" data-parent="#accordion2">
                <div class="flex-table-cell px-0 py-0 w-100">
                  <div class="accordion-body">
                    <table class="table table-visit-orders">
                        <thead>
                          <tr>
                            <th>Наименование</th>
                            <th style="width: 140px">Кол-во человек</th>
                            <th style="width: 115px">Дата поездки</th>
                          </tr>
                        </thead>
                        <tbody>
                          <td>{{item.visit.title}}</td>
                          <td>{{item.user_count}}</td>
                          <td>{{item.visit_date}}</td>
                        </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-md-center" v-if="!completeItems || !(completeItems.length > 0)">
          <div class="ss-header">
            <span class="title-desc">
              <strong>Тут пока ничего нет.</strong>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, post } from "@/lib/axios";
import { DeclensionMaker } from "@/utilities/declension-maker.ts";
import { showConfirm } from "@/lib/swal2";
import "bootstrap/js/src/tab";
import { mapGetters, mapActions } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import LoadIcon from "../../../components/visit/LoadIcon.vue";
import FileIcon from "../../../components/visit/FileIcon.vue";
import ClearFile from "../../../components/visit/ClearFile.vue";
import DangerIcon from "../../../components/visit/DangerIcon.vue";
import Tooltip from "../../../components/visit/Tooltip.vue";
import { success, showMessage } from "@/lib/toasted";

const ProductStatuses = Object.freeze( {
  Accepted: 1,
  Processing: 2,
  TransferredToDelivery: 3,
  Delivered: 4,
  Cancelled: 5,
} );

const ServiceStatuses = Object.freeze( {
  Accepted: 1,
  Confirmed: 2,
  Processing: 3,
  Cancelled: 4,
  Done: 5,
} );

export default {
  name: "Orders",
  data: () => ( {
    ProductStatuses,
    ServiceStatuses,
    activeItems: [],
    activeServiceItems: [],
    completeItems: [],
    completeServiceItems: [],
    fileName: '',
    fileLoad: '',
    curator_name: '',
    dinner_place: '',
    have_city_excursion: false,
    have_lunch: false,
    have_photo: false,
    have_questions: false,
    hotel: '',
    state_number: '',
    time: '',
    transport: '',
    disabledFile: false,
    disabledForm: false,
    has_file: false
  } ),
  methods: {
    ...mapActions( "account", [ "getUserInfo" ] ),
    ...mapActions( "catalog", [ "goToProductCardInOrders" ] ),
    postRequest ( id )
    {
      const {
        dinner_place,
        have_city_excursion,
        have_lunch,
        have_photo,
        have_questions,
        hotel,
        state_number,
        time,
        transport,
      } = this

      const data = {
        dinner_place,
        have_city_excursion,
        have_lunch,
        have_photo,
        have_questions,
        hotel,
        state_number,
        time,
        transport,
      }
      post( `visit-order/save-plan/${ id }`, data ).then( ( response ) =>
      {
        success( 'Заявка успешно отправлена!' )
      } )
    },
    loadFile ( id )
    {
      get( `visit-order/get-file/${ id }`, {}, { responseType: "blob" } ).then( ( response ) =>
      {
        const fileURL = window.URL.createObjectURL( new Blob( [ response.data ] ) );
        const fileLink = document.createElement( 'a' );
        fileLink.href = fileURL;
        fileLink.setAttribute( 'download', `Участники поездки.xlsx` );
        document.body.appendChild( fileLink );

        fileLink.click();
      } )
    },
    uploadFile ( id )
    {
      const input = document.createElement( 'input' );
      input.type = 'file';
      input.accept = '.xls,.xlsx';

      input.onchange = e =>
      {
        const file = e.target.files[ 0 ];
        const filesFormData = new FormData();
        filesFormData.append( 'file', file );
        post( `visit-order/save-from-file/${ id }`, filesFormData )
        this.fileName = file.name;
      }

      input.click();
    },
    getFile ( id )
    {
      this.dinner_place = ''
      this.have_city_excursion = false
      this.have_lunch = false
      this.have_photo = false
      this.have_questions = false
      this.hotel = ''
      this.state_number = ''
      this.time = ''
      this.transport = ''
      this.disabledFile = false
      this.disabledForm = false
      this.has_file = false
      this.fileName = ''
      get( `/visit-order/get-file/${ id }` ).then( ( response ) =>
      {
        const fileURL = window.URL.createObjectURL( new Blob( [ response.data ] ) );
        this.fileLoad = fileURL
      } ).catch( ( error ) =>
      {
        console.log( error );
      } )
      get( `visit-order/get-visit-order-by-id/${ id }` ).then( ( response ) =>
      {
        this.disabledForm = response.data.last_week
        this.disabledFile = response.data.hidden_file
        this.has_file = response.data.has_file
        const visit = response.data.visitOrderPlan
        this.dinner_place = visit.dinner_place
        this.have_city_excursion = visit.have_city_excursion === '0' ? false : true
        this.have_lunch = visit.have_lunch === '0' ? false : true
        this.have_photo = visit.have_photo === '0' ? false : true
        this.have_questions = visit.have_questions === '0' ? false : true
        this.hotel = visit.hotel
        this.state_number = visit.state_number
        this.time = visit.time
        this.transport = visit.transport
      } )
    },
    loadGetFile ( id )
    {
      get( `/visit-order/get-file/${ id }`, {}, { responseType: "blob" } ).then( ( response ) =>
      {
        const fileURL = window.URL.createObjectURL( new Blob( [ response.data ] ) );
        const fileLink = document.createElement( 'a' );
        fileLink.href = fileURL;
        fileLink.setAttribute( 'download', `Список участников поездки.xlsx` );
        document.body.appendChild( fileLink );

        fileLink.click();
      } )
    },
    clearFile (id)
    {
      post(`/visit-order/clear-file/${id}`).then((response) => {
        success( 'Файл успешно удален!' )
      }).catch((e) => {
        console.log(e)
      })
      this.fileName = ''
      this.fileLoad = ''
    },
    getServiceStatusClass ( status )
    {
      let className = "";
      if ( status === 1 )
      {
        className = "accepted";
      }
      if ( status === 2 )
      {
        className = "confirmed";
      }
      if ( status === 3 )
      {
        className = "processing";
      }
      if ( status === 4 )
      {
        className = "canceled";
      }
      if ( status === 5 )
      {
        className = "done";
      }
      console.log(className)
      return className;
    },
    async fetchData ()
    {
      await get( "profile/orders", { type: "active" } ).then( ( response ) =>
      {
        this.activeItems = response.data.data.products.filter( item => item.visit );
      } );
      await get( "profile/orders", { type: "complete" } ).then( ( response ) =>
      {
        this.completeItems = response.data.data.products.filter( item => item.visit );
      } );
    },
    makeDeclension ( price )
    {
      return DeclensionMaker.getDeclension( price, "балл", "балла", "баллов" );
    },
    canCancelProduct ( status )
    {
      return status === ProductStatuses.Accepted;
    },
    canCancelService ( status )
    {
      return status === ServiceStatuses.Accepted;
    },
    async cancelProductOrder ( orderId )
    {
      const { isConfirmed } = await showConfirm( "Вы уверены, что хотите отменить данный заказ?" );
      if ( isConfirmed )
      {
        await post( "product-order/cancel", { order_id: orderId } );
        await this.fetchData();
        await this.getUserInfo();
      }
    },
    async cancelEducationOrder ( orderId )
    {
      const { isConfirmed } = await showConfirm( "Вы уверены, что хотите отменить данный заказ?" );
      if ( isConfirmed )
      {
        await post( "education/course-order/cancel", { order_id: orderId } );
        await this.fetchData();
        await this.getUserInfo();
      }
    },
    async cancelServiceOrder ( orderId )
    {
      const { isConfirmed } = await showConfirm( "Вы уверены, что хотите отменить данный заказ?" );
      if ( isConfirmed )
      {
        await post( "visit-order/cancel", { order_id: orderId } );
        await this.fetchData();
        await this.getUserInfo();
      }
    },
    productCardParams ( item )
    {
      return {
        item,
        sectionPath: item.section_path,
        categoryPath: item.category_path,
        productPath: item.seo_path,
      };
    },
  },
  computed: {
    ...mapGetters( "account", [ "accountInfo", "pointsNumber" ] ),
  },
  mounted ()
  {
    this.fetchData();
  },
  metaInfo ()
  {
    return {
      title: `Профиль - История заказов - ЛУКМАРКЕТ`,
    };
  },
  components: {
    LoadIcon,
    FileIcon,
    ClearFile,
    DangerIcon,
    ValidationObserver,
    ValidationProvider,
    Tooltip
  }
};
</script>

<style scoped lang="scss">
.tooltip-icon {
  margin: 0 8px;
  cursor: pointer;
}

.visit-order-form {
  &__title {
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
    margin-top: 24px;
  }

  &__container {
    margin-top: 18px;
    display: flex;
    flex-wrap: wrap;
  }

  &__element-title {
    font-size: 16px;
    line-height: 110%;
    color: #212529;
    width: 430px;
    display: flex;
    align-items: center;
  }

  &__element-content {
    display: flex;

    &-label {
      padding: 5px 10px;
      font-size: 14px;
    }
  }
}

.visit-detail__block-list-title {
  max-width: 406px;
  width: 100%;
}

.visit-detail__block {
  margin-top: 24px;
}

.visit-detail__description {
  width: 100%;
  max-width: 638px;
}

.ss-header {
  padding: 20px 0;
  border-bottom: 2px solid #f5f5f5;
}

.table {
  position: relative;
}

.accordion-body {
  white-space: normal !important;
}

.accordion {
  &-header {
    position: relative;

    .table {
      white-space: nowrap;
    }
  }

  &__document-block {
    padding: 20px 0;
    border-top: 2px solid #CCD8EA;
    border-bottom: 2px solid #CCD8EA;

    &-title {
      font-size: 14px;
      line-height: 100%;
      padding: 8px 0;
      margin-bottom: 16px;
      border-bottom: 1px solid #CCD8EA;
    }

    &-desc {
      width: 100%;
      max-width: 332px;
      font-size: 14px;
      font-weight: 400;
      margin: 8px 0;
      font-family: Futuris
    }

    &-load {
      display: flex;
      align-items: center;
      color: #D2233C;
      margin: 20px 0;
      cursor: pointer;
      opacity: 1;
      transition: all 0.3s ease-in-out;

      &:hover {
        opacity: 0.75;
      }
    }

    &-document {
      display: flex;
      align-items: center;

      & svg {
        margin-right: 10px;

        &:nth-last-child(1) {
          margin: 0 10px
        }
      }

      &-load {
        color: #D2233C !important;
        padding: 8px 12px;
        border: 1px solid #D2233C;
        cursor: pointer;
        opacity: 1;
        transition: all 0.3s ease-in-out;
        margin: 24px 0;
        width: 134px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFFF;

        &:hover {
          opacity: 0.75;
        }
      }

      &-desc {
        display: flex;
        max-width: 638px;
        width: 100%;

        & svg {
          width: 50px;
          margin-right: 10px;
        }
      }

      &-text {
        font-size: 14px;
        line-height: 110%;
        color: #D2233C;
      }

    }
  }

  &-toggle {
    &:after {
      content: "";
      position: absolute;
      right: 15px;
      top: 16px;
      border-style: solid;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(225deg);
      vertical-align: middle;
      color: #555;
    }
  }
}

.nav-tabs {
  flex-wrap: nowrap;
}

.nav-item {
  display: inline-block !important;
  margin: 0;
}

.collapsed:after {
  transform: rotate(45deg);
}

.accordion-body {
  border-top: none;
}

.product-order-status {
  vertical-align: middle;
  position: relative;
  margin-left: 10px;
  padding-left: 35px;

  @media (min-width: 992px) {
    margin-left: 0;
  }

  &.canceled {
    color: #ff103e;
  }

  &.done:before {
    background-image: url("../../../assets/images/correct.svg");
  }

  &.accepted:before {
    background-image: url("../../../assets/images/wallet.svg");
    background-repeat: no-repeat;
  }

  &.processing:before {
    background-image: url("../../../assets/images/work.svg");
    background-repeat: no-repeat;
  }

  &.shipping:before {
    background-image: url("../../../assets/images/package.svg");
    background-repeat: no-repeat;
  }

  &.shipped:before {
    background-image: url("../../../assets/images/tag.svg");
    background-repeat: no-repeat;
  }

  &.canceled:before {
    background-image: url("../../../assets/images/cancel.svg");
    background-repeat: no-repeat;
  }
}

.product-order-status:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: -5px;
  width: 24px;
  height: 24px;
}

.visit-request-order-status {
  vertical-align: middle;
  position: relative;
  margin-left: 25px;

  @media (max-width: 992px) {
    margin-left: 45px;
  }

  &.canceled {
    color: #ff103e;
  }

  &.accepted:before {
    background-image: url("../../../assets/images/wallet.svg");
    background-repeat: no-repeat;
  }

  &.processing:before {
    background-image: url("../../../assets/images/work.svg");
    background-repeat: no-repeat;
  }

  &.confirmed:before {
    background-image: url("../../../assets/images/tag.svg");
    background-repeat: no-repeat;
  }

  &.done:before {
    background-image: url("../../../assets/images/correct.svg");
  }

  &.canceled:before {
    background-image: url("../../../assets/images/cancel.svg");
    background-repeat: no-repeat;
  }
}

.visit-request-order-status:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: -35px;
  top: -5px;
  width: 24px;
  height: 24px;
}

.accordion-header {
  cursor: pointer;
}

.table {
  font-size: 16px;
  min-width: 385px;
}

.table a {
  font-weight: normal;
}

.table thead th {
  border: none !important;
}

.prod-table.table th,
.prod-table.table td {
  width: 25% !important;

  @media (max-width: 768px) {
    font-size: 14px;
  }
}

.product-order_number {
  font-weight: bold;
}

.addr {
  margin-left: 10px;
  padding-left: 40px;
  position: relative;

  @media (max-width: 992px) {
    margin-top: 10px;
  }
}

.addr:before {
  content: url("../../../assets/images/placeholder.svg");
  position: absolute;
  left: 0;
  top: 4px;
}

.desc-title {
  display: inline-block;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.14;
}

.additional-info-text {
  margin: 0 13px;
  font-size: 18px;
}

.flex-table {
  display: flex;
  flex-direction: column;

  &-header {
    font-weight: bold;
    align-items: center;

    @media (max-width: 992px) {
      display: none !important;
    }
  }

  &-row {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: 992px) {
      flex-direction: row;
    }

    &.collapse {
      display: none;
    }

    &.collapse.show {
      display: flex;
    }
  }

  &-cell {
    padding: 0.5rem;
    word-wrap: break-word;

    @media (min-width: 992px) {
      padding: 1rem;
      width: calc(100% / 4);
    }
  }
}

.accordion {
  &-header {
    @media (max-width: 992px) {
      padding: 0.5rem 0;
    }
  }

  &__items {
    display: flex;
    align-items: center;
    margin: 20px;

    &-title {
      color: #495057;
      font-size: 12px;
      line-height: 150%;
      font-weight: 600;
      display: flex;
      align-items: center;
      margin-right: 14px;
    }

    &-desc {
      font-size: 12px;
      line-height: 110%;
    }
  }

  &-item {
    border-top: 1px solid #ddd;

    &:first-child {
      @media (max-width: 992px) {
        border-top: none;
      }
    }
  }

  &-body {
    white-space: nowrap;
  }
}

.table td,
.table th {
  width: 15%;
}

.disabled {
  color: #A0A0A0 !important;
  border: 1px solid #A0A0A0;
  cursor: default;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  display: none;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #b5b5b5;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 2.3px;
  background-color: white;
  -webkit-transition: .3s;
  transition: .3s;
}

input:checked+.slider {
  background-color: #E4183E;
}

input:focus+.slider {
  box-shadow: 0 0 1px #E4183E;
}

input:checked+.slider:before {
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
